module.exports = {
  siteTitle: 'Max Cello', // <title>
  manifestName: 'MaxCello',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/mxcello/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Max Cello',
  subHeading: 'Professional Cellist and Cello and Piano Teacher',
  // social
  socialLinks: [
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com',
    },
    {
      icon: 'fa-facebook',
      name: 'Facebook',
      url: 'https://facebook.com',
    }
  ],
  phone: '07970 014715',
  address: '305 Del Sol, Sunnyvale, CA',
};
