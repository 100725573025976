import React, { useState } from 'react';
import { serialize } from 'dom-form-serializer';
import { stringify } from 'qs';
import config from '../../config';
import errorIcon from '../assets/images/error_icon.png';

export default function Footer() {
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [delivered, setDelivered] = useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    setNameError(false);
    setDelivered(false);
    setEmailError(false);
    setMessageError(false);
    const form = e.target;
    const data = serialize(form);
    if (data.name.length === 0) {
      setNameError(true);
      return;
    }
    if (data.email.length === 0) {
      setEmailError(true);
      return;
    }
    if (data.message.length === 0) {
      setMessageError(true);
      return;
    }
    fetch(form.action + '?' + stringify(data), {
      method: 'POST',
    })
      .then(res => {
        if (res.ok) {
          setDelivered(true);
          setTimeout(() => {
            setDelivered(false);
          }, 6000);
          return res;
        } else {
          throw new Error('Network error');
        }
      })
      .then(() => {
        form.reset();
        // this.setState({
        //   alert: this.props.successMessage,
        //   disabled: false,
        // });
      })
      .catch(err => {
        console.error(err);
        // this.setState({
        //   disabled: false,
        //   alert: this.props.errorMessage,
        // });
      });
  };
  return (
    <section id="footer">
      <div className="inner">
        <h2 className="major">Contact Maxine</h2>
        <form
          method="post"
          action="/"
          data-netlify="true"
          netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
          name="contact-form-maxine"
        >
          <div className="fields">
            <div className="field">
              <p className="ohnohoney">
                <label>
                  Don’t fill this out if you are human
                  <input name="bot-field" />
                </label>
              </p>
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
              {nameError && (
                <div
                  style={{
                    position: 'absolute',
                    marginTop: '2px',
                    display: 'flex',
                    lineHeight: '16px',
                    color: 'red',
                  }}
                >
                  <div style={{ marginRight: 2 }}>
                    <img
                      style={{ height: 16, width: 16 }}
                      src={errorIcon}
                      alt="error"
                    />
                  </div>
                  Name is required.
                </div>
              )}
            </div>
            <div className="field">
              <label htmlFor="email">Email</label>
              <input type="email" name="email" id="email" />
              {emailError && (
                <div
                  style={{
                    position: 'absolute',
                    marginTop: '2px',
                    display: 'flex',
                    lineHeight: '16px',
                    color: 'red',
                  }}
                >
                  <div style={{ marginRight: 2 }}>
                    <img
                      style={{ height: 16, width: 16 }}
                      src={errorIcon}
                      alt="error"
                    />
                  </div>
                  Email not supplied, or incorrect format.
                </div>
              )}
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
              {messageError && (
                <div
                  style={{
                    position: 'absolute',
                    marginTop: '2px',
                    display: 'flex',
                    lineHeight: '16px',
                    color: 'red',
                  }}
                >
                  <div style={{ marginRight: 2 }}>
                    <img
                      style={{ height: 16, width: 16 }}
                      src={errorIcon}
                      alt="error"
                    />
                  </div>
                  Please Enter a Message
                </div>
              )}
            </div>
          </div>
          <input type="hidden" name="form-name" value="contact-form-maxine" />
          <ul className="actions">
            <li>
              <input type="submit" value="Send Message" />
            </li>
          </ul>
          {delivered && (
            <div
              style={{
                border: '1px solid',
                padding: '3px',
                paddingLeft: '8px',
                borderRadius: '8px',
                backgroundColor: '#454558',
                textAlign: 'center',
              }}
            >
              YOUR MESSAGE WAS DELIVERED
            </div>
          )}
        </form>
        <ul className="contact">
          {/* <li className="fa-home">{config.address}</li> */}

          <li className="fa-phone">{config.phone}</li>

          {/* {config.socialLinks.map(social => {
            const { icon, url } = social;
            return (
              <li className={`${icon}`} key={url}>
                <a href={url}>{url}</a>
              </li>
            );
          })} */}
        </ul>
        <ul className="copyright">
          <li>
            Site Created By: <a href="https://www.clockworkbee.co.uk">CLOCKWORK BEE</a>
          </li>
        </ul>
      </div>
    </section>
  );
}
